<template>
  <v-autocomplete
    v-model="selected"
    label="Competitors"
    :items="items"
    clearable
    item-text="name"
    item-value="id"
    @input="loadFC"
    multiple
  ></v-autocomplete>
</template>

<script>
import poiService from "@/services/PointOfInterestService.js";

export default {
  data: () => ({
    selected: null,
    items: []
  }),
  computed: {},
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.items = await poiService.GetPointOfInterests();
    },
    async loadFC() {
      
      Window.poiFeatureGroup.clearLayers();

      if(!this.selected)
        return

      let fc = await poiService.GetPointOfInterestFeatureCollection(
        this.selected
      );

      window.L.geoJSON(fc, {
        onEachFeature: feature => {
          let lat = feature.geometry.coordinates[1];
          let lon = feature.geometry.coordinates[0];

          var myIcon = window.L.icon({
            iconUrl: feature.properties.featureImageUrl,
            iconSize: [25, 30],
            iconAnchor: [22, 30],
            popupAnchor: [-3, -76]
          });

          let newLayer = window.L.marker([lat, lon], { icon: myIcon }).bindPopup("<p>" + feature.properties.featureName + "</p>");
          Window.poiFeatureGroup.addLayer(newLayer);
        }
      });
    }
  }
};
</script>