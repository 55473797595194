<template>
    <v-autocomplete
    :value="values"
    label="Reward levels"
     :items="items"
     clearable
     @input="set"
     item-text="name"
     item-value="id"
     multiple
    ></v-autocomplete>
</template>

<script>

    import dataService from "@/services/DataService"

  export default {
    data: () => ({
      items: []
    }),
    computed:{
      values(){
        return this.$store.getters["filter/filterRequest"].rewardLevels
      }
    },    
    mounted(){
      this.load();
    },
    methods:{
        async load(){
            this.items = await dataService.GetRewardLevels();
        },
        set(val){
            this.$store.dispatch("filter/setRewardLevels", val)
        }

    }
  }
</script>