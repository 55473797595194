<template>

<v-card height="500" :loading="loading" class="pa-2" elevation="0">
 <v-card-title>
{{ featureName }}
 </v-card-title>
 

<div v-if="!loading">
  <v-row dense>
    <v-col cols="12">
        <v-card elevation="6" class="pa-2">
          <barchart :id="'shapeChart1'" :legend="false" :chart="merchantChart" :indexAxis="'y'" :width="150" :height="200"/>
        </v-card>
    </v-col>
    <v-col cols="12">
      <v-card elevation="6">
<barchart :id="'shapeChart2'" :chart="customerSegmentChart" :indexAxis="'x'" :width="100" :height="200"/>
      </v-card>
    </v-col>
  </v-row>
</div>
<v-card-text v-else>
    <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="card"
    ></v-skeleton-loader>
</v-card-text>

 
    
</v-card>
</template>

<script>
import targetService from "@/services/TargetService";
import barchart from "@/components/charts/ChartJs/BarChart.vue"


export default {
  components: {
    barchart
  },
  props: {
    feature: { default: null, type: Object }
  },
  data: () => ({
    loading: false,
    merchantChart: null,
    customerSegmentChart: null
  }),
  computed: {

featureName(){
  return this.feature ? this.feature.properties.featureName : null
},
featureAreaType(){
  return this.feature ? this.feature.properties.areaType : null
},
featureId(){
  return this.feature ? this.feature.properties.featureId : null
}

  },
  mounted() {},
  methods: {

    async getCharts(){
      this.loading = true
      this.customerSegmentChart = await targetService.GetMunicipalityCustomerSegmentLevelChart(this.featureId)
      this.merchantChart = await targetService.GetMunicipalityMerchantChart(this.featureId)
      
      // this.$store.dispatch("chart/setCharts", [customerSegmentLevelBarChart, merchantBarChart]);
      
      this.loading = false

    },
    
    }
};

</script>