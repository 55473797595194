import Vue from "vue";

export default new (class PointOfInterestService {

  async GetPointOfInterest(id) {
    const path = `/api/PointOfInterests/PointOfInterest/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetPointOfInterests() {
    const path = `/api/PointOfInterests/PointOfInterests`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetPointOfInterestFeatureCollection(id) {
    const path = `/api/PointOfInterests/FeatureCollection`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: id
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }
  
})();
