<template>
  <v-navigation-drawer
    v-model="showNavDrawer"
    hide-overlay
    :stateless="showNavDrawer"
    app
    temporary
    @input="drawerInput"
    width="450px"
  >

                  <v-btn
                @click="drawerInput(false)"
                color="primary"
                dark
                absolute
                bottom
                left
                fab
                x-small
                class="mb-10"
              >
                <v-icon x-small>mdi-minus</v-icon>
              </v-btn>

  <v-toolbar color="primary" dark dense>
       <v-toolbar-title>Selections</v-toolbar-title>
      <!-- <v-app-bar-nav-icon @click="drawerInput(false)"></v-app-bar-nav-icon> -->
  </v-toolbar>
    <v-container>
      <v-row dense>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span
                >Segment
                <v-icon v-if="isSegmentFilterActive" class="ml-5"
                  >mdi-check</v-icon
                ></span
              >
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row dense>
                <v-col lg="8" md="8" cols="12">
                  <segmentselect />
                </v-col>
                <v-col lg="8" md="8" cols="12">
                  <partnerselect />
                </v-col>     
                <v-col lg="8" md="8" cols="12">
                  <rewardlevelselect/>
                </v-col> 
                <v-col lg="8" md="8" cols="12">
                <cardissuerselect />                           
                </v-col>
                <v-col lg="8" md="8" cols="12">
                <cardtypeselect />                           
                </v-col>                
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span
                >Business
                <v-icon v-if="isBusinessFilterActive" class="ml-5"
                  >mdi-check</v-icon
                ></span
              >
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row dense>
                <v-col cols="12">
                  <lineofbusiness />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>          


          
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span
                >Salesrep
                <v-icon v-if="isSalesrepFilterActive" class="ml-5"
                  >mdi-check</v-icon
                ></span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col lg="8" md="8" cols="12">
                  <salesrepselect :showPlotAreaFunction="true" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span
                >Merchants
                <v-icon v-if="isMerchantFilterActive" class="ml-5"
                  >mdi-check</v-icon
                ></span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col lg="8" md="8" cols="12">
                <merchantcountryselect />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col lg="8" md="8" cols="12">
                  <merchanttypeselect />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <merchantselect :plotOnSelection="true" />
                </v-col>
                <!-- <v-col cols="12">
                  <merchantuptakeform />
                </v-col> -->
                <v-col lg="6" cols="12">
                  <transactiondatefrom />
                </v-col>
              <v-col lg="6" cols="12">
                  <transactiondateto />
                </v-col>                
              </v-row>

              



            </v-expansion-panel-content>
          </v-expansion-panel>

                    <v-expansion-panel>
            <v-expansion-panel-header>
              <span
                >Competitors</span
              >
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row dense>
                <v-col lg="6" md="6" cols="12">
                  <poiselect />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

                    <v-expansion-panel>
            <v-expansion-panel-header>
              <span
                >Geography</span
              >
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row dense>
                <v-col  cols="12">
                  <municipalityselect />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
      </v-row>
     
      <v-row dense class="mt-5" align-top>
        <v-col lg="4" md="8" cols="12">
        <createtargetbutton />
        </v-col>
      </v-row>
      <v-row dense class="mt-5" align-top justify="start">
                <v-col cols="6" >
        <targetactionsmenu />
        </v-col>
      </v-row>

    </v-container>
  </v-navigation-drawer>
</template>


<script>
import salesrepselect from "@/components/filters/autocompletes/SalesrepSelect.vue";
import segmentselect from "@/components/filters/autocompletes/SegmentSelect.vue";
import cardissuerselect from "@/components/filters/autocompletes/CardIssuerSelect.vue";
import cardtypeselect from "@/components/filters/autocompletes/CardTypeSelect.vue";
import merchantcountryselect from "@/components/filters/autocompletes/MerchantCountrySelect.vue";
import merchantselect from "@/components/filters/autocompletes/MerchantSelect.vue";
import merchanttypeselect from "@/components/filters/autocompletes/MerchantTypeSelect.vue";
import municipalityselect from "@/components/filters/autocompletes/MunicipalitySelect.vue"
import partnerselect from "@/components/filters/autocompletes/PartnerSelect"
import rewardlevelselect from "@/components/filters/autocompletes/RewardLevelSelect"
// import merchantuptakeform from "@/components/uptake/MerchantUptakeForm.vue";
import transactiondatefrom from "@/components/filters/datepickers/TransactionDateFrom.vue";
import transactiondateto from "@/components/filters/datepickers/TransactionDateTo.vue";
import lineofbusiness from "@/components/filters/treeviews/LineOfBusiness.vue";
import createtargetbutton from "@/components/filters/CreateTargetButton.vue";
import targetactionsmenu from "@/components/filters/TargetActionsMenu.vue";
import poiselect from "@/components/pointofinterests/PointOfInterestSelect"





export default {
  components: {
    segmentselect,
    salesrepselect,
    cardissuerselect,
    cardtypeselect,
    merchantcountryselect,
    merchanttypeselect,
    merchantselect,
    poiselect,
    municipalityselect,
    partnerselect,
    rewardlevelselect,
    // merchantuptakeform,
    transactiondatefrom,
    transactiondateto,
    lineofbusiness,
    createtargetbutton,
    targetactionsmenu
  },
  data: () => ({
    items: [],
    value: null,
  }),
  computed: {
    showNavDrawer() {
      return this.$store.getters["util/showNavDrawer"];
    },
    filterRequest() {
      return this.$store.getters["filter/filterRequest"];
    },
    lifecycleType(){
      return this.$store.getters["filter/filterRequest"].lifecycleType
    },         
    isTargetCreated() {
      return this.$store.getters["filter/result"];
    },    
    isSegmentFilterActive() {
      return this.filterRequest.segments.length > 0;
    },
    isBusinessFilterActive() {
      return this.filterRequest.lineOfBusinesses.length > 0;
    },    
    isSalesrepFilterActive() {
      return this.filterRequest.salesreps.length > 0;
    },
    isMerchantFilterActive() {
      return this.filterRequest.merchants.length > 0 || this.filterRequest.transactionDateTo || this.filterRequest.transactionDateFrom || this.filterRequest.merchantTypes.length > 0
      || this.filterRequest.merchantCountries.length > 0
    },    
  },
  methods: {
    drawerInput(val) {
      this.$store.dispatch("util/setNavDrawer", val);
    }
    
  },
};
</script>