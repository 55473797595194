<template>
<v-container>

<v-row>
    <v-row>
       <v-radio-group :value="valueType" row>
      <v-radio
        v-for="n in lifeCycles"
        :key="n.id"
        :label="n.name"
        :value="n.id"
        @change="setType(n.id)"
      ></v-radio>
    </v-radio-group>
  </v-row>
</v-row>

<v-row>
    <v-autocomplete
    :value="values"
    label="Segment"
     :items="itemsFiltered"
     clearable
     @input="set"
     item-text="name"
     item-value="id"
     multiple
    >
        <template v-slot:selection="{ item, index }">
      <v-chip small v-if="index === 0">
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text caption"
        >(+{{ values.length - 1 }})</span
      >
    </template>
    </v-autocomplete>
    <!-- <span class="ma-0" style="font-size:12px">{{ filteredText }}</span> -->
</v-row>
</v-container>
</template>

<script>

    import segmentService from "@/services/SegmentService"

  export default {
    data: () => ({
      items: [],
      lifeCycles: [{id: 1, name: "Customer"}, {id: 2, name: "Prospect"} ]
    }),
    computed:{
      values(){
        return this.$store.getters["filter/filterRequest"].segments
      },
      valueType(){
        return this.$store.getters["filter/filterRequest"].lifecycleType
      },      
      itemsFiltered() {

        let filtered = this.items.filter(i =>
          i.lifecycleType == this.valueType);
          return filtered;
 
      }
      , 
    filteredText() {
      return `Showing ${this.itemsFiltered.length} of ${this.items.length}`;
    }             
    },    
    mounted(){
      this.load();
    },
    methods:{
        async load(){
            this.items = await segmentService.GetSegments();
        },
        set(val){
            this.$store.dispatch("filter/setSegments", val)
        },
        setType(val){          
          this.$store.dispatch("filter/setSegments", [])
          this.$store.dispatch("filter/setLifecycleType", val)
        }

    }
  }
</script>