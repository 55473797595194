<template>
  <v-card :loading="loading" class="pa-2" elevation="10" rounded>
    <v-card-subtitle>
      Selected areas
    </v-card-subtitle>
 <v-simple-table dense  height="100">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" style="font-size:10px">
            
          </th>
          <th class="text-left" style="font-size:10px">
            Code
          </th>
          <th class="text-left" style="font-size:10px">
            Name
          </th>
        <th class="text-left" style="font-size:10px">
            #Cus A/B/C/D
          </th>    
        <th class="text-left" style="font-size:10px">
            #Cus Email
          </th>   
        <th class="text-left" style="font-size:10px">
            Pro. Heavy/Light
          </th>                             
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in areaStats"
          :key="i"
          style="font-size:6px"
          :active="true"
        >
          <td style="font-size:10px">
              <!-- <v-simple-checkbox  style="display:inline" :ripple="false" :value="isSelected(item)" @input="toggleSelected(item)">
              </v-simple-checkbox > -->
              <v-icon small  style="display:inline" @click="remove(item)">
                mdi-delete
              </v-icon>
          </td>
          <td style="font-size:10px">{{ item.code }}</td>
          <td style="font-size:10px">{{ item.name }}</td>
          <td style="font-size:10px">{{ item.customersA }}/ {{ item.customersB }}/ {{ item.customersC }}/ {{ item.customersD }}</td>
          <td style="font-size:10px">{{ item.customersEmail }}/{{ item.customersPhone }}</td>
          <td style="font-size:10px">{{ item.prospectsHeavy }}/{{ item.prospectsLight }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

    
  </v-card>
</template>

<script>
//  import targetService from "@/services/TargetService";

export default {
  components: {},
  props: {},
  data: () => ({
    loading: false,
    selected: [],
  }),
  computed: {
    areas() {
      return this.$store.getters["target/areas"];
    },
     areaStats(){
       return this.$store.getters["target/areaStatistics"]; 
     }
    // areaStats(){
    //   let filtered = this.statistics.filter((i) => { 
    //     return this.areas.some(n => parseInt(n.featureId) == i.areaId)
    //     }); 
    //   return filtered;
    // }
  },
  mounted() {},
  methods: {
    isSelected(item){
      return this.selected.some((i) => i.areaId == item.areaId)
    },
    toggleSelected(item){

      if(this.isSelected(item)){
        let filtered = this.selected.filter((i) => { return i.areaId !== item.areaId }); 
        this.selected = filtered
      }
      else {
        this.selected.push(item)
      }
    },
    remove(item){
      if(this.isSelected(item))
      {
        this.toggleSelected(item)
      }     
    
      this.$store.dispatch("target/removeArea", item.areaId);
    },     
  }
};
</script>