<template>
    <v-autocomplete
    :value="values"
    label="Merchant types"
     :items="items"
     clearable
     @input="set"
     item-text="name"
     item-value="id"
     multiple
    ></v-autocomplete>
</template>

<script>

    import merchantService from "@/services/MerchantService"

  export default {
    data: () => ({
      items: []
    }),
    computed:{
      values(){
        return this.$store.getters["filter/filterRequest"].merchantTypes
      }
    },    
    mounted(){
      this.load();
    },
    methods:{
        async load(){
            this.items = await merchantService.GetMerchantTypes();
        },
        set(val){
            this.$store.dispatch("filter/setMerchantTypes", val)
        }

    }
  }
</script>