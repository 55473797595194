<template>

  <v-card 
    class="mx-auto"
    width="100%"
  >
  
    <v-sheet class="pa-4 primary lighten-2">

  <v-overlay :value="loading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

      <v-text-field
        v-model="search"
        label="Search on line of business"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        dense
      >
            <template v-slot:append-outer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon color="primary" @click="clear">mdi-close</v-icon>
        </v-slide-x-reverse-transition>
      </template>
      </v-text-field>

    </v-sheet>
    <v-card-text>
        
        <div class="smart_tree_view_wrapper">
      <v-treeview class="smart_tree_view"
      v-model="selection"
        :items="items"
        :transition="true"
        dense   
        selectable
        :filter="filter"  
        :search="search"
        @input="update"                
      >
        <template v-slot:prepend="{ item }">
          <v-icon
            v-if="item.children"
            v-text="`mdi-${item.id === 1 ? 'home-variant' : 'folder-network'}`"
          ></v-icon>
        </template>
      </v-treeview>
        </div>
    </v-card-text>
    <v-card-text>
        
    </v-card-text>
  </v-card>
</template>

<style scoped>

.smart_tree_view_wrapper {
  
  width: 100%;
  height: 300px

}

.smart_tree_view {
  display: block;
  font-size: 11px;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

</style>
<script>
import dataService from "@/services/DataService";
import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      selection: [],
      items: [],
      open: [],
      search: null,
      caseSensitive: false,
      loading:false
    }),
    computed: {
         ...mapGetters({
      filterRequest: 'filter/filterRequest',
 }),
  selected(){
    return this.filterRequest.industryCodes
  } ,
      filter () {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      }
    },
      mounted(){
      this.load();
      
  },

   methods: {
     clear(){
        this.selection = []
        this.$store.dispatch("filter/setLineOfBusinesses", []);
     },
      async load(){
          this.loading = true;
        this.items = await dataService.GetLineOfBusinesses()          
        this.loading = false;
        
         this.selection = this.items.filter(i => this.selected.includes(i.id))
        // this.selection = this.selected
      },
    update(val){
 let children = this.items.reduce(function iter(r, a) {
        if (Array.isArray(a.children)) {
            return a.children.reduce(iter, r);
        }
        r.push(a);
        return r;
    }, []);



      const payload = children.filter(c => val.includes(c.id)).map(_ => _.id);
      this.$store.dispatch("filter/setLineOfBusinesses", payload);
    }      
  }
  }
</script>