<template>
 <v-speed-dial
 v-model="fab"
 dark
                absolute
                bottom
                right
                fab
                x-small
                
    >
      <template v-slot:activator>

        
        <v-btn
          v-model="fab"
          color="blue darken-2"
          dark
          fab
          
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
        <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        dark
        small
        color="warning"
        @click="clearMap"
                     v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
  
         </template>
      <span>Clear Map</span>
    </v-tooltip>
        <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        dark
        small
        color="red"
        @click="clearAll"
             v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
         </template>
      <span>Clear all</span>
    </v-tooltip>
    </v-speed-dial>
            <!-- <v-btn
              small
                @click="clearAll"
                color="error"
                dark
                absolute
                bottom
                right
                fab
                class="mb-10"
                
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn> -->

</template>


<script>

export default {
  data: () => ({
    fab: false,
    loading: false,
    items: [],
    value: null,
    errors: []
  }),
  computed: {
    filterRequest() {
      return this.$store.getters["filter/filterRequest"];
    },
    isSegmentFilterActive(){
        return this.filterRequest.segments.length > 0
    },
    isSalesrepFilterActive(){
        return this.filterRequest.salesreps.length > 0
    }    
  },
  methods: {
      clearAll(){
        this.$store.dispatch("filter/setResult", null)
        this.$store.dispatch("filter/reset")
        this.$store.dispatch("chart/reset")
        this.clearMap()
        this.$emit("clear")
      },
      clearMap(){
        Window.organizationsClusterGroup.clearLayers()
        Window.drawFeatureGroup.clearLayers();
        Window.merchantFeatureGroup.clearLayers();
        Window.poiFeatureGroup.clearLayers();
        Window.municipalityFeatureGroup.clearLayers();
        Window.heatmapFeatureGroup.clearLayers();
        this.$store.dispatch("target/setAreas", { selection: [], targetGroupId: null});
      }               
  },
};
</script>