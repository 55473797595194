<template>
  <div class="leaflet" v-bind:id="mapid"></div>
</template>


<script>
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet-draw";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
import "../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "../../../node_modules/leaflet.bigimage/dist/Leaflet.BigImage.min.css";

import "leaflet.gridlayer.googlemutant";
import "leaflet.bigimage"
// delete L.Icon.Default.prototype._getIconUrl;
delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });

export default {
  props: ["id"],
  name: "app",
  data: () => ({
    mapid: ""
  }),

  beforeMount() {
    this.mapid = this.$props.id;
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const mapopts = {
        maxZoom: 20,
        minZoom: 2,
        zoomControl: false
      };

      Window[this.id] = L.map(this.id, mapopts).setView(
        [59.334591, 17.06324],
        6
      );

      L.control
        .zoom({
          position: "topright"
        })
        .addTo(Window[this.id]);


      L.control.BigImage().addTo(Window[this.id]);

 var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
        Window[this.id].addLayer(layer);

      L.gridLayer
        .googleMutant({
          maxZoom: 24,
          type: "roadmap"
        })
        .addTo(Window[this.id]);
        

        const trafficMutant = L.gridLayer.googleMutant({
        maxZoom: 24,
        type: "roadmap"
      });
      trafficMutant.addGoogleLayer("TrafficLayer");

    
    Window["organizationsClusterGroup"] = new L.MarkerClusterGroup({
      //disableClusteringAtZoom: 13,
      maxClusterRadius: 40
    });
    Window["organizationsClusterGroup"]._getExpandedVisibleBounds = function() {
      return Window["organizationsClusterGroup"]._map.getBounds();
    };
    Window["organizationsClusterGroup"].addTo(Window[this.id]);
    
    Window["merchantFeatureGroup"] = new L.FeatureGroup();
    Window["merchantFeatureGroup"].addTo(Window[this.id]);

    Window["poiFeatureGroup"] = new L.FeatureGroup();
    Window["poiFeatureGroup"].addTo(Window[this.id]);    

    Window["municipalityFeatureGroup"] = new L.FeatureGroup();
    Window["municipalityFeatureGroup"].addTo(Window[this.id]);  
    
    Window["postalCodeFeatureGroup"] = new L.FeatureGroup();
    Window["postalCodeFeatureGroup"].addTo(Window[this.id]);      

    Window["heatmapFeatureGroup"] = new L.FeatureGroup();
    Window["heatmapFeatureGroup"].addTo(Window[this.id]);
    
    Window["drawFeatureGroup"] = new L.FeatureGroup();
    

    }
  }
};
</script>