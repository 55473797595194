<template>
  <v-container>
    <v-row dense>
      <v-radio-group v-model="country" row @change="plot">
        <v-radio v-for="n in countries" :key="n.id" :label="n.name" :value="n.id"></v-radio>
      </v-radio-group>

    </v-row>
          <v-row dense>
          <v-btn x-small color="info" @click="clear"> clear
          </v-btn>
      </v-row>
  </v-container>
</template>

<script>
import targetService from "@/services/TargetService";
import shapepopup from "@/components/leaflet/popups/Shape.vue";

//pass to dynamically loaded components
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import store from "@/store/store";

export default {
  data: () => ({
    countries: [
      { id: "SE", name: "SE municipality" },
      { id: "NO", name: "NO municipality" }
    ],
    country: null,
    items: [],
    loading: false,
    selectedPolygons: []
  }),
  computed: {
    values() {
      return this.$store.getters["filter/filterRequest"].municipalities;
    },
    itemsFiltered() {
      if (this.country) {
        let filtered = this.items.filter(i => i.country == this.country);
        return filtered;
      }
      return [];
      //return this.items;
    },
    filteredText() {
      return `Showing ${this.itemsFiltered.length} of ${this.items.length}`;
    },
    targetGroupId() {
      let result = this.$store.getters["filter/result"];

      if (!result) return null;
      else return result.id;
    },
    salesreps() {
      return this.$store.getters["filter/filterRequest"].salesreps;
    }
  },
  mounted() {},
  methods: {
    clear(){
        this.country = null
        this.selectedPolygons = []
        this.$store.dispatch("target/setAreas", { selection: [], targetGroupId: null});
        this.$store.dispatch("filter/setAreaCodes", [] )
        Window.municipalityFeatureGroup.clearLayers();
    },
    async plot() {
      this.loading = true;
      this.$store.dispatch("util/setLoading", {
        visible: true,
        text: "Fetching geometries"
      });
      const fc = await targetService.GetMunicipalitiesFeatureCollection(
        this.country,
        this.salesreps,
        this.targetGroupId
      );

      if(fc == null){
        const snackbar = { show: true, message: "", errors: ["Could not load polygons"] }
         this.$store.dispatch("util/setSnackbar", snackbar);
         this.loading = false;
         this.$store.dispatch("util/resetLoading");
         return
      }

      Window.municipalityFeatureGroup.clearLayers();

      let x = window.L.geoJSON(fc, {
        onEachFeature: (feature, layer) => {
          layer.on("click", async () => {
            let featureId = feature.properties.featureId;
            let isSelected = this.selectedPolygons.some(i => i.featureId == featureId);
            if (isSelected) {
              let filtered = this.selectedPolygons.filter(i => { return i.featureId !== featureId;});
              this.selectedPolygons = filtered;
              //set default style
              layer.setStyle({
                fillColor: "gray",
                color: "black",
                weight: 1,
                fillOpacity: 0.2
              });
              this.$store.dispatch("target/setAreas", { selection: filtered, targetGroupId: this.targetGroupId});
              return;
            } else {
              //higlight selected polygon
              layer.setStyle({
                fillColor: "gray",
                color: "white",
                weight: 4,
                fillOpacity: 0.2
              });
            }

            let request = {
              leafletId: layer._leaflet_id,
              featureName: feature.properties.featureName,
              featureId: feature.properties.featureId,
              areaType: feature.properties.areaType
            };

            this.selectedPolygons.push(request);
            this.$store.dispatch("target/setAreas", { selection: this.selectedPolygons, targetGroupId: this.targetGroupId});
            this.$store.dispatch("filter/setAreaCodes", this.selectedPolygons.map(i => i.featureId) )
          });

          layer.on("contextmenu", async e => {
            let ComponentClass = Vue.extend(shapepopup);
            let lpop = new ComponentClass({
              vuetify,
              store: store,
              propsData: {  feature: feature }
            }).$mount().$el;

            
              lpop.__vue__.getCharts();
              console.log(e);

            window.L.popup({minWidth: "700"})
              .setLatLng(e.latlng)
              .setContent(lpop)
              .openOn(Window.leafletmap);
          });

          

          layer.setStyle({
            fillColor: "gray",
            color: "black",
            weight: 1,
            fillOpacity: 0.2
          });
        }
      });
      Window.municipalityFeatureGroup.addLayer(x);

      this.loading = false;
      this.$store.dispatch("util/resetLoading");
    }
  }
};
</script>