<template>
  <div>
    <div>
      <filternavigation />
    </div>

    <div class="mapwrapper">
      <leaflet id="leafletmap"></leaflet>
      <div id="toggleFilter">
        <v-btn @click="setFilterNavigation(true)" color="primary" dark absolute bottom left fab x-small>
          <v-icon x-small>mdi-plus</v-icon>
        </v-btn>
      </div>
      <div id="selectedAreas" v-if="showAreas">
      <selectedpolygons />
      </div>
      <cleartargetbutton />
    </div>


  </div>
</template>

<style scoped>
.leaflet-tile {
  text-align: left !important;
}

.mapwrapper {
  /* position: absolute; */
  height: 100%;
  width: 100%;
  z-index: 1000;
}

#toggleFilter {
  position: absolute;
  bottom: 0;
  margin-bottom: 3em;
  z-index: 1;
}

#selectedAreas {
  position: absolute;
  bottom: 0;
  margin-bottom: 3em;
  right: 10em;
  z-index: 1;
}



#leafletmap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>

<script>
import leaflet from "@/components/leaflet/Leaflet.vue";
import filternavigation from "@/components/filters/FilterNavigation.vue";
import cleartargetbutton from "@/components/filters/ClearTargetButton.vue";

import selectedpolygons from "@/components/leaflet/SelectedPolygonList.vue";
// import isochronepopup from "@/components/leaflet/popups/Isochrone.vue";
// import shapepopup from "@/components/leaflet/popups/Shape.vue";
// //pass to dynamically loaded components
// import Vue from "vue";
// import vuetify from "@/plugins/vuetify";
// import store from "@/store/store";

//Leaflet settings
const L = window.L;

export default {
  name: "Map",
  components: {
    leaflet,
    filternavigation,
    cleartargetbutton,
    selectedpolygons
  },
  data: () => ({
    snackbar: true
  }),
  mounted() {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });

    Window.leafletmap.addLayer(Window.drawFeatureGroup);
    var drawControl = new L.Control.Draw({
      position: "topright",
      draw: {
        polygon: {
          metric: true,
          allowIntersection: false,
          showArea: true,
          drawError: {
            color: "#b00b00",
            timeout: 1000
          }
        },

        rectangle: false,
        circlemarker: false,
        polyline: false,
        circle: true,
        marker: false
      },
      edit: {
        featureGroup: Window.drawFeatureGroup,
        remove: true
      }
    });
    Window.leafletmap.addControl(drawControl);

    Window.leafletmap.on(L.Draw.Event.CREATED, async event => {
      this.addDrawing(event);
      this.setGeometries();
    });

    Window.leafletmap.on(L.Draw.Event.EDITED, async () => {
      this.setGeometries();
    });

    Window.leafletmap.on(L.Draw.Event.DELETED, async event => {
      Window.drawFeatureGroup.removeLayer(event.layer);
      this.setGeometries();
    });
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    targetInfo() {
      return this.$store.getters["filter/result"];
    },
    showAreas() {
      return this.$store.getters["target/areas"].length > 0;
    }    
  },
  methods: {
    setFilterNavigation(val) {
      this.$store.dispatch("util/setNavDrawer", val);
    },
    setGeometries() {
      {
        let requests = [];
        let request = {};

        Window.drawFeatureGroup
          .getLayers()
          .filter(x => getShapeType(x) !== "marker")
          .forEach(l => {
            const type = getShapeType(l);
            switch (type) {
              case "circle":
                request = {
                  geometryType: type,
                  radius: l._mRadius,
                  origo: [l._latlng.lat, l._latlng.lng]
                };
                break;
              case "polygon":
                request = {
                  geometryType: type,
                  polygonLine: l._latlngs[0].map(_ => [_.lat, _.lng])
                };
                break;
              default:
              // code block
            }
            requests.push(request);
          });
        this.$store.dispatch("filter/setGeometries", requests);
      }
    },
    addDrawing(event) {
      Window.drawFeatureGroup.addLayer(event.layer, true);
    },




  }
};

var getShapeType = function(layer) {
  if (layer instanceof L.Circle) {
    return "circle";
  }

  if (layer instanceof L.Marker) {
    return "marker";
  }

  if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
    return "polyline";
  }

  if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
    return "polygon";
  }

  if (layer instanceof L.Rectangle) {
    return "rectangle";
  }
};


</script>