<template>
    <v-autocomplete
      :value="values"
      label="Merchant country"
      :items="items"
      clearable
      @input="set"
      item-text="name"
      item-value="id"
      multiple
      class="mb-0"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <span>{{ item.name }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption">(+{{ values.length - 1 }})</span>
      </template>
    </v-autocomplete>
</template>

<script>

export default {

  data: () => ({
    loading: false,
    items: [{id: 'SE', name:"SE"}, {id: 'NO', name:"NO"}]
  }),
  watch: {
  },
  computed: {
    values() {
      return this.$store.getters["filter/filterRequest"].merchantCountries;
    }
  },
  mounted() {
  },
  methods: {
    set(val) {
      this.$store.dispatch("filter/setMerchantCountries", val);
    }
  }
};
</script>