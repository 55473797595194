<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        label="Transaction date to"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      >
              <template v-slot:append-outer>
                <v-icon left @click="clear">
                      mdi-close
                    </v-icon>
            </template>
      </v-text-field>
    </template>
    <v-date-picker :value="value" no-title scrollable @change="set">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    date: null,
    menu: false
  }),
  computed: {
    value() {
      return this.$store.getters["filter/filterRequest"].transactionDateTo;
    }
  },
  methods: {
      clear(){
          this.$store.dispatch("filter/setTransactionDateTo", null);
      },    
    set(val) {
            this.$store.dispatch("filter/setTransactionDateTo", val);
    }
  }
};
</script>