import Vue from "vue";

export default new (class SegmentService {

  async GetLineOfBusinesses() {
    const path = `/api/data/LineOfBusinesses`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetPartners() {
    const path = `/api/data/Partners`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async GetCardIssuers() {
    const path = `/api/data/CardIssuers`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }   
  
  async GetCardTypes() {
    const path = `/api/data/CardTypes`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }      

  async GetRewardLevels() {
    const path = `/api/data/RewardLevels`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  
 
})();
